// todos:
// - remove/hide elements, add support for more stuff
// - add dimension to recording and respect when render
// - resize event
// - put it all in a container
// - make sure the browser doesnt die after a while
// - js fade in /  lazyloading
// - display text input
// - make it phone friendly
//

import React, { useEffect, useRef, useState } from "react";

// Helper function to decode HTML entities
const decodeHtmlEntities = (str) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = str;
  return txt.value;
};

// Helper function to check if content is CSS code
const isCssCode = (content) => {
  // Simple check to see if content contains CSS selectors or style definitions
  return content && /{[^}]*}/.test(content);
};

// Helper function to handle CSS code in mutations
const handleCssMutation = (cssCode) => {
  const decodedCssCode = decodeHtmlEntities(cssCode);

  // Create or select a <style> element
  let styleElement = document.getElementById("dynamic-styles");
  if (!styleElement) {
    styleElement = document.createElement("style");
    styleElement.id = "dynamic-styles";
    document.head.appendChild(styleElement);
  }

  // Append the CSS code to the <style> element
  styleElement.appendChild(document.createTextNode(decodedCssCode));
};

// Helper function to create an element from a node object
const createElementFromNode = (node) => {
  const element = document.createElement(node.nodeName.toLowerCase());

  // Set attributes
  node.attributes.forEach((attr) => {
    element.setAttribute(attr.name, attr.value);
  });

  // Set styles if 'style' attribute is present
  const styleAttr = node.attributes.find((attr) => attr.name === "style");
  if (styleAttr) {
    element.style.cssText = styleAttr.value;
  }

  // Set innerHTML or textContent
  if (node.innerHTML) {
    element.innerHTML = decodeHtmlEntities(node.innerHTML);
  } else if (node.textContent) {
    element.textContent = decodeHtmlEntities(node.textContent);
  }

  // Recursively process child nodes
  if (node.childNodes && node.childNodes.length > 0) {
    node.childNodes.forEach((childNode) => {
      const childElement = createElementFromNode(childNode);
      element.appendChild(childElement);
    });
  }

  return element;
};

// The main DOMRenderer component
const DOMRenderer = () => {
  const [domTree] = useState('<div id="chmlncontent"></div>');
  const domFrameRef = useRef(null);
  const cursorRef = useRef(null);

  useEffect(() => {
    // Set initial DOM snapshot on first render
    if (domFrameRef.current) {
      domFrameRef.current.innerHTML = domTree;
    }

    // WebSocket connection to the Node.js server
    const socket = new WebSocket("wss://streamer-server.mnke.io");

    // Listen for WebSocket messages (real-time mutations)
    socket.onmessage = (event) => {
      const mutation = JSON.parse(event.data);
      console.log("Received mutation:", mutation);

      switch (mutation.type) {
        case "initial_dom_snapshot":
          const contentDiv = domFrameRef.current.querySelector("#chmlncontent");
          contentDiv.innerHTML = mutation.data;
          break;
        case "dom_mutation":
          applyDomMutation(mutation);
          break;
        case "scroll":
          handleScrollEvent(mutation);
          break;
        case "mousemove":
          handleMouseMoveEvent(mutation);
          break;
        default:
          console.log("Discarding event:", mutation);
      }
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    // Cleanup on component unmount
    return () => {
      socket.close();
    };
  }, [domTree]);

  const handleMouseMoveEvent = (eventData) => {
    const { pageX, pageY } = eventData;

    if (cursorRef.current && domFrameRef.current) {
      // Calculate the position relative to the container
      const rect = domFrameRef.current.getBoundingClientRect();
      const x = pageX - rect.left + domFrameRef.current.scrollLeft;
      const y = pageY - rect.top + domFrameRef.current.scrollTop;

      cursorRef.current.style.left = `${x}px`;
      cursorRef.current.style.top = `${y}px`;
    }
  };

  const handleScrollEvent = (eventData) => {
    const { scrollX, scrollY } = eventData;
    if (domFrameRef.current) {
      domFrameRef.current.scrollLeft = scrollX;
      domFrameRef.current.scrollTop = scrollY;
      console.log(`Scrolled to position: (${scrollX}, ${scrollY})`);
    }
  };

  const applyDomMutation = (mutation) => {
    const contentDiv = domFrameRef.current.querySelector("#chmlncontent");

    console.log("Mutation data:", mutation.data);
    const mutationData = JSON.parse(mutation.data);
    console.log("Parsed mutation data:", mutationData);

    if (mutationData) {
      const {
        type,
        target,
        addedNodes,
        removedNodes,
        attributeName,
        oldValue,
        previousSibling,
        nextSibling,
      } = mutationData;

      /** Decode HTML Entities in target properties **/
      if (target) {
        if (target.outerHTML) {
          target.outerHTML = decodeHtmlEntities(target.outerHTML);
        }
        if (target.textContent) {
          target.textContent = decodeHtmlEntities(target.textContent);
        }
      }

      /** Handle mutations based on type **/
      switch (type) {
        case "attributes":
          /** Handle attribute mutations **/
          handleAttributeMutation(target, attributeName);
          break;

        case "characterData":
          /** Handle character data mutations **/
          handleCharacterDataMutation(target, oldValue);
          break;

        case "childList":
          /** Handle added nodes **/
          if (addedNodes && addedNodes.length > 0) {
            console.log("Processing added nodes:", addedNodes);
            addedNodes.forEach((node) => {
              if (node.outerHTML) {
                node.outerHTML = decodeHtmlEntities(node.outerHTML);
              }
              if (node.textContent) {
                node.textContent = decodeHtmlEntities(node.textContent);
              }
              processNode(node, target, "add", contentDiv, mutationData);
            });
          }

          /** Handle removed nodes **/
          if (removedNodes && removedNodes.length > 0) {
            console.log("Processing removed nodes:", removedNodes);
            removedNodes.forEach((node) => {
              processNode(node, target, "remove", contentDiv);
            });
          }
          break;

        default:
          console.warn(`Unknown mutation type: ${type}`);
      }
    }
  };

  /** Helper function to handle attribute mutations **/
  const handleAttributeMutation = (target, attributeName) => {
    const element = findElement(target);
    if (element) {
      // Update the attribute
      const newAttrValue =
        target.attributes.find((attr) => attr.name === attributeName)?.value ||
        "";
      console.log(
        `Updating attribute ${attributeName} of element ${element.tagName} to ${newAttrValue}`,
      );
      element.setAttribute(attributeName, newAttrValue);

      // If the attribute is 'style', apply styles properly
      if (attributeName === "style") {
        element.style.cssText = newAttrValue;
      }

      // If the attribute is 'class', update class list
      if (attributeName === "class") {
        element.className = newAttrValue;
      }
    } else {
      console.warn(`Element not found for attribute mutation.`, target);
    }
  };

  /** Helper function to handle character data mutations **/
  const handleCharacterDataMutation = (target, oldValue) => {
    // Find the parent element
    const parentElement = findParentElement(target);
    if (parentElement) {
      // Find the text node to update
      const textNodes = Array.from(parentElement.childNodes).filter(
        (node) => node.nodeType === 3 && node.textContent === (oldValue || ""),
      );
      if (textNodes.length > 0) {
        textNodes[0].textContent = target.data;
        console.log(`Updated text content to: ${target.data}`);
      } else {
        console.warn(`Text node not found for character data mutation.`);
      }
    } else {
      console.warn(`Parent element not found for character data mutation.`);
    }
  };

  /** Helper function to find an element based on target data **/
  const findElement = (target) => {
    let element = null;
    if (target.attributes) {
      const idAttr = target.attributes.find((attr) => attr.name === "id");
      if (idAttr) {
        element = document.getElementById(idAttr.value);
      } else if (target.nodeName) {
        // Construct a selector from attributes
        const selector =
          target.nodeName.toLowerCase() +
          target.attributes
            .map((attr) => `[${attr.name}="${attr.value}"]`)
            .join("");
        element = document.querySelector(selector);
      }
    }
    return element;
  };

  /** Helper function to find parent element for a text node **/
  const findParentElement = (target) => {
    // If the target has a parent element ID, use it
    if (target.parentElementId) {
      return document.getElementById(target.parentElementId);
    } else {
      // Fallback to contentDiv
      return domFrameRef.current.querySelector("#chmlncontent");
    }
  };

  /** Helper function to process individual nodes **/
  const processNode = (
    node,
    parentTarget,
    action,
    contentDiv,
    mutationData = {},
  ) => {
    const parentElement = findElement(parentTarget) || contentDiv;
    if (!parentElement) {
      console.warn("Parent element not found for processNode.", parentTarget);
      return;
    }

    if (node.nodeType === 1) {
      // Element node
      if (action === "add") {
        const newElement = createElementFromNode(node);

        // Determine where to insert the new element
        let referenceNode = null;
        if (mutationData.nextSibling) {
          referenceNode = findElement(mutationData.nextSibling);
        }

        if (referenceNode) {
          parentElement.insertBefore(newElement, referenceNode);
          console.log(
            "Inserted new element before reference node:",
            newElement,
          );
        } else {
          parentElement.appendChild(newElement);
          console.log("Appended new element:", newElement);
        }
      } else if (action === "remove") {
        const elementToRemove = findElement(node);
        if (elementToRemove) {
          elementToRemove.remove();
          console.log("Removed element:", elementToRemove);
        } else {
          console.warn("Element to remove not found:", node);
        }
      }
    } else if (node.nodeType === 3) {
      // Text node
      if (action === "add") {
        const textNode = document.createTextNode(node.textContent);

        // Determine where to insert the text node
        let referenceNode = null;
        if (mutationData.nextSibling) {
          referenceNode = findElement(mutationData.nextSibling);
        }

        if (referenceNode) {
          parentElement.insertBefore(textNode, referenceNode);
          console.log("Inserted text node before reference node:", textNode);
        } else {
          parentElement.appendChild(textNode);
          console.log("Appended text node:", textNode);
        }
      } else if (action === "remove") {
        // Remove text node
        const childTextNodes = Array.from(parentElement.childNodes).filter(
          (child) =>
            child.nodeType === 3 && child.textContent === node.textContent,
        );
        childTextNodes.forEach((textNode) => {
          parentElement.removeChild(textNode);
          console.log("Removed text node:", textNode);
        });
      }
    }
  };

  /** Helper function to create an element from a node object **/
  const createElementFromNode = (node) => {
    const element = document.createElement(node.nodeName.toLowerCase());

    // Set attributes
    if (node.attributes) {
      node.attributes.forEach((attr) => {
        element.setAttribute(attr.name, attr.value);
      });
    }

    // Set styles if 'style' attribute is present
    const styleAttr = node.attributes?.find((attr) => attr.name === "style");
    if (styleAttr) {
      element.style.cssText = styleAttr.value;
    }

    // Set innerHTML or textContent
    if (node.innerHTML) {
      element.innerHTML = decodeHtmlEntities(node.innerHTML);
    } else if (node.textContent) {
      element.textContent = decodeHtmlEntities(node.textContent);
    }

    // Recursively process child nodes
    if (node.childNodes && node.childNodes.length > 0) {
      node.childNodes.forEach((childNode) => {
        const childElement = createElementFromNode(childNode);
        element.appendChild(childElement);
      });
    }

    return element;
  };

  return (
    <div
      ref={domFrameRef}
      style={{
        position: "relative",
        overflow: "auto",
        height: "100vh",
        width: "100%",
      }}
    >
      {/* The cursor element */}
      <div
        ref={cursorRef}
        style={{
          position: "absolute",
          width: "10px",
          height: "10px",
          backgroundColor: "red",
          borderRadius: "50%",
          pointerEvents: "none",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
        }}
      ></div>
    </div>
  );
};

export default DOMRenderer;
